<div class="page-content-container">
  <div class="page-content-tabs">
    <app-settings-tab-picker
      [tabs]="tabs"
      [defaultTab]="defaultTab"
      (tabChanged)="setActiveTab($event)"
    ></app-settings-tab-picker>
  </div>

  <div *ngIf="activeTab === tabs[0].text" class="tab-container">
    <app-user-settings></app-user-settings>
  </div>
  <div *ngIf="activeTab === tabs[1].text" class="tab-container">
    <app-salutations></app-salutations>
  </div>
  <div *ngIf="activeTab === tabs[2].text" class="tab-container">
    <app-template-defaults></app-template-defaults>
  </div>
  <div *ngIf="activeTab === tabs[3].text" class="tab-container">
    <app-bucket-details></app-bucket-details>
  </div>
  <div *ngIf="activeTab === tabs[4].text" class="tab-container">
    <app-filter-configs></app-filter-configs>
  </div>
  <div *ngIf="activeTab === tabs[5].text" class="tab-container">
    <app-cc-framework></app-cc-framework>
  </div>
  <div *ngIf="activeTab === tabs[6].text" class="tab-container">
    <app-field-definition-details></app-field-definition-details>
  </div>
  <div *ngIf="activeTab === tabs[7].text" class="tab-container">
    <app-content-definition-details></app-content-definition-details>
  </div>
  <div *ngIf="activeTab === tabs[8].text" class="tab-container">
    <app-messages></app-messages>
  </div>
  <div *ngIf="activeTab === tabs[9].text" class="tab-container">
    <app-outgoing-messages></app-outgoing-messages>
  </div>
  <div *ngIf="activeTab === tabs[10].text" class="tab-container">
    <app-sent-messages></app-sent-messages>
  </div>
  <div *ngIf="activeTab === tabs[11].text" class="tab-container">
    <app-monitoring-outgoing-messages></app-monitoring-outgoing-messages>
  </div>
  <div *ngIf="activeTab === tabs[12].text" class="tab-container">
    <app-system-status></app-system-status>
  </div>
  <div *ngIf="activeTab === tabs[13].text" class="tab-container inner-padding">
    <app-report-request-creator></app-report-request-creator>
  </div>
  <div *ngIf="activeTab === tabs[14].text" class="tab-container inner-padding">
    <app-report-request></app-report-request>
  </div>
  <div *ngIf="activeTab === tabs[15].text" class="tab-container">
    <app-report-log-list></app-report-log-list>
  </div>
  <div *ngIf="activeTab === tabs[16].text" class="tab-container">
    <app-disclaimer></app-disclaimer>
  </div>
  <div *ngIf="activeTab === tabs[17].text" class="tab-container">
    <app-image-gallery></app-image-gallery>
  </div>
  <div *ngIf="activeTab === tabs[18].text" class="tab-container">
    <app-link-creator></app-link-creator>
  </div>
</div>
