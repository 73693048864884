<form
  [formGroup]="salutationForm"
  class="aspark-container-padding"
  *ngIf="salutationForm"
>
  <div class="row row-single">
    <mat-form-field>
      <mat-label>{{ 'language' | translate }}</mat-label>
      <mat-select formControlName="language">
        <mat-option *ngFor="let language of languages" [value]="language">
          {{ language.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'channel' | translate }}</mat-label>
      <mat-select formControlName="channelType" (selectionChange)="updateCodeTables()">
        <mat-option>{{ 'none' | translate }}</mat-option>
        <mat-option
          *ngFor="let channelType of channelTypes"
          [value]="channelType"
        >
          {{ channelType.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="row row-single">
    <fieldset>
      <legend>{{ 'availableTemplates' | translate }}</legend>
      <ul>
        <li *ngFor="let template of templates">
          <button mat-raised-button class="chip" (click)="onInsert(template)">{{ template.name }}</button>
        </li>
      </ul>
      <div>
        {{ 'templateUsage' | translate }}
        <pre>
        <p>{{'Hello {templateValue}!'}}</p>
        </pre>
        <a target="_blank" href="https://quarkus.io/guides/qute-reference"
          >Reference guide</a
        >
      </div>
    </fieldset>
  </div>
  <div class="row row-single">
    <mat-form-field *ngIf="fieldDefinition.editorType === FieldDefinitionType.PLAIN">
      <mat-label>{{ 'text' | translate }}</mat-label>
      <textarea
        #textEditor
        matInput formControlName="value" type="text">
      </textarea>
    </mat-form-field>
    <div
         *ngIf="fieldDefinition.editorType === FieldDefinitionType.RICHTEXT"
    >
      <mat-label>{{ 'text' | translate }}</mat-label>
      <textarea
        #richEditor
        [froalaEditor]="editorOptions"
        (froalaInit)="initialize($event)"
        formControlName="value"
      ></textarea>
    </div>
  </div>
</form>
