<div class="content-def-container aspark-container-padding">
  <app-hub-languages *ngIf="fallbackRules" noPadding [selected]="fallbackRules" (selectedChanged)="saveFallbackRule($event)"></app-hub-languages>
  <app-card class="content-definitions">
    <div card-header>
      <span>{{ 'contentDefinitions' | translate }}</span>
    </div>
    <div card-action>
      <button mat-stroked-button (click)="onAdd()">
        <app-config-icon iconIdentifier="open_in_new"></app-config-icon>
        {{ 'contentDefinitionAdd' | translate }}
      </button>
    </div>
    <div card-body>
      <app-grid
        tableId="content-definition-list"
        [rowData]="contentDefinitions"
        [columnDefs]="contentDefColumnDefs"
        [gridOptions]="contentDefGridOptions"
      ></app-grid>
    </div>
  </app-card>
  <app-card class="hcd-mappings">
    <div card-header>
      <span>{{ 'hubChannelDefinitionMapping' | translate }}</span>
    </div>
    <div card-action>
      <button mat-stroked-button (click)="onUpdateMappings()">
        <app-config-icon iconIdentifier="open_in_new"></app-config-icon>
        {{ 'hubChannelDefinitionMappingUpdateShort' | translate }}
      </button>
    </div>
    <div card-body>
      <app-grid
        tableId="hcd-mapping-list"
        [rowData]="hubChannelDefinitionMappings"
        [columnDefs]="mappingColumnDefs"
        [gridOptions]="mappingGridOptions"
      ></app-grid>
    </div>
  </app-card>
</div>
