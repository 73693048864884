import {Component, OnInit} from "@angular/core";
import {ImageGallery, ImageGalleryService} from "../../../../api/core";
import {TranslateService} from "@ngx-translate/core";
import {NotificationService} from "../../../../services/notification.service";

const imgObserver = new IntersectionObserver(
  (entries, observer) => {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0.0) {
        const img = entry.target as HTMLImageElement;
        if (!img.hasAttribute('src')) {
          const src = img.dataset.src;
          img.setAttribute('src', src);
        }
      }
    });
  },
  {}
)

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html'
})
export class ImageGalleryComponent implements OnInit {
  maxHeight: string = '';
  images: ImageGallery[];

  constructor(
    readonly translateService: TranslateService,
    readonly imageGalleryService: ImageGalleryService,
    readonly notificationService: NotificationService,
  ) {
  }

  async ngOnInit() {
    setTimeout(() => {
      this.updateHeight();
      this.onData();
    });
  }

  onData() {
    this.imageGalleryService.getImages()
      .subscribe((page) => {
        this.images = page;
        setTimeout(() => this.observeImages());
      })
  }

  onClose(image: ImageGallery) {
    this.imageGalleryService.toggleClosed(image.id).subscribe(images => {
      image.closed = !image.closed;
      this.notificationService.handleSuccess(this.translateService.instant(`imageGallery${image.closed ? 'Closed' : 'Opened'}`));
    });
  }

  private updateHeight() {
    const el = document.getElementById('image-gallery-container');
    if (!el || this.maxHeight) return;
    this.maxHeight = `max-height: ${el.parentElement.clientHeight-8}px;`;
  }

  private observeImages() {
    const el = document.getElementById('image-gallery-container');
    for (let img of Array.from(el.querySelectorAll('img'))) {
      imgObserver.observe(img);
    }
  }
}
