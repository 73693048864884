import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { LicenseManager } from 'ag-grid-enterprise';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {FroalaEditorModule} from "angular-froala-wysiwyg";

if (environment.production) {
  enableProdMode();
}
// ag-grid license
LicenseManager.setLicenseKey(
  'Using_this_AG_Grid_Enterprise_key_( AG-036610 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Confinale AG )_is_granted_a_( Single Application )_Developer_License_for_the_application_( aSpark )_only_for_( 2 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( aSpark )_need_to_be_licensed___( aSpark )_has_been_granted_a_Deployment_License_Add-on_for_( 2 )_Production_Environments___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 18 June 2024 )____[v2]_MTcxODY2NTIwMDAwMA==84b54df88c8e614f7dc927bcfff3b6ae'
);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
