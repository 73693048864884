import {Component} from '@angular/core';
import {ResourceStatus, SystemStatus, SystemStatusService} from "../../../api/core";
import {GridApi, GridOptions} from "ag-grid-community";

@Component({
  selector: 'app-system-status',
  templateUrl: './system-status.component.html',
})
export class SystemStatusComponent {

  gridApi: GridApi;
  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    defaultColDef: {
      suppressHeaderMenuButton: true,
    },
    rowClassRules: {
      'ag-row-disabled': (params) => params.data.status === ResourceStatus.OPERATIONAL,
      'error-status': (params) => params.data.status !== ResourceStatus.OPERATIONAL
    },
    onGridReady: (params) => {
      this.gridApi = params.api;
    }
  };
  columns = [
    {headerName: 'Resource', field: 'name', sortable: true, filter: true},
    {headerName: 'Status', field: 'status', sortable: true, filter: true},
  ];

  rowData = [];
  systemStatus: SystemStatus = undefined;

  constructor(private systemStatusService: SystemStatusService) {
    this.fetchSystemStatus();
  }

  fetchSystemStatus() {
    this.systemStatusService.getSystemStatus()
      .subscribe(data => {
        this.systemStatus = data;
        this.rowData = Object.keys(data.additionalData).map(key => {
          return {
            name: key,
            status: data.additionalData[key]
          };
        });
        this.gridApi?.refreshCells();
      });
  }
}
