<form [formGroup]="formGroup" >
  <mat-form-field class="form-link">
    <mat-label>Redirect Link</mat-label>
    <input matInput formControlName="link" type="text" readonly />
    <button type="button" mat-raised-button color="primary" matSuffix (click)="onCopyRequest()">
      <app-config-icon iconIdentifier="copy"></app-config-icon>
      {{'copy' | translate}}
    </button>
  </mat-form-field>
  <mat-form-field class="form-target">
    <mat-label>Target</mat-label>
    <mat-select formControlName="target" (selectionChange)="updateTarget()">
      <mat-option *ngFor="let target of targets" [value]="target">
        {{ target }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="showIdent">
    <mat-label>Ident</mat-label>
    <input
      type="text"
      matInput
      [formControl]="identControl"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete
      autoActiveFirstOption
      #auto="matAutocomplete"
      (optionSelected)="onIdentSelect($event)"
    >
      <mat-option
        *ngFor="let option of filteredIdents$ | async"
        [value]="option.ident"
      >
        {{ option.name }} ({{ option.ident }})
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field class="form-param" *ngFor="let param of visibleParams" (change)="updateLink()"  [hintLabel]="param.tooltip">
    <mat-label>{{param.key}}</mat-label>
    <input matInput type="text" [formControlName]="param.key" [placeholder]="param.tooltip" />
  </mat-form-field>
  <mat-form-field class="form-filter" *ngFor="let filter of filterItems" (change)="updateLink()" [hintLabel]="filter.tooltip">
    <mat-label>{{filter.key}}</mat-label>
    <input matInput type="text" [formControlName]="filter.key" [placeholder]="filter.tooltip" />
  </mat-form-field>
</form>
