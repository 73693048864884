import {Component} from "@angular/core";
import {FormBuilder, Validators} from "@angular/forms";
import {ExportRequest, ExportRequestBody, ReportService} from "../../../../api/core";
import {NotificationService} from "../../../../services/notification.service";
import {catchError} from "rxjs/operators";
import {of} from "rxjs";

@Component({
  selector: 'app-report-request',
  templateUrl: 'report-request.component.html'
})
export class ReportRequestComponent {

  form = this.fb.group({
    ident: ['', [Validators.required, Validators.maxLength(255)]],
    body: ['', [Validators.required, Validators.maxLength(10000)]],
  })

  constructor(
    private fb: FormBuilder,
    private reportService: ReportService,
    private notificationService: NotificationService,
  ) {
  }

  onSend() {
    const values = this.form.getRawValue();
    const {ident, body} = values;
    let root: ExportRequest;
    try {
      root = JSON.parse(body);
    } catch (e) {
      this.notificationService.handleError(e.message||'Error parsing body');
      return;
    }
    this.reportService.generateReport(ident, root)
      .pipe(
        catchError((err, caught) => {
          console.log({err, caught});
          return of({ok: false, error: err.error.error || 'ERROR'});
        })
      )
      .subscribe((ok: {ok: boolean; error?: string;}) => {
        if (ok.error) {
          this.notificationService.handleError(ok.error);
        } else if (ok.ok) {
          this.notificationService.handleSuccess("Report Request sent successfully");
          this.form.patchValue({
            ident: '',
            body: '',
          });
        }
    });
  }
  onClearFields() {
    this.form.patchValue({
      ident: '',
      body: '',
    })
  }
  onIdentNow() {
    const now = new Date();
    const date = [
      now.getFullYear().toString(),
      (now.getMonth()+1).toString().padStart(2, '0'),
      now.getDate().toString().padStart(2, '0')
    ].join('');
    const time = [
      now.getHours().toString().padStart(2, '0'),
      now.getMinutes().toString().padStart(2, '0'),
      now.getSeconds().toString().padStart(2, '0'),
    ].join('');
    this.form.patchValue({
      ident: [date, time].join('-')
    })
  }
}
